import React from "react";
import Menu from "./Menu"; // Asegúrate de importar el componente correctamente

function BaseLayout({ children }) {
  return (
    <>
      <Menu />
      {children}
    </>
  );
}

export default BaseLayout;
